<template>
  <div>
    <div class="min-vh-100 container-box">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
            ประสิทธิภาพของสินค้า ({{ rows | numeral("0,0") }})
          </h1>
        </b-col>
        <b-col xl="8" class="text-right d-flex justify-content-end">
          <b-input-group class="panel-input-serach">
            <b-form-input
              class="input-serach"
              placeholder="ค้นหาด้วยชื่อสินค้า"
              v-model="filter.search"
              @keyup="handleSearch"
            ></b-form-input>
            <b-input-group-prepend @click="btnSearch">
              <span class="icon-input m-auto pr-2">
                <font-awesome-icon icon="search" title="View" />
              </span>
            </b-input-group-prepend>
          </b-input-group>
          <b-button v-b-toggle.sidebar-1 class="btn-filter">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline">ค้นหา</span>
          </b-button>
          <b-dropdown class="ml-1 lang-dd border-0" right>
            <b-dropdown-item-button @click="exportFile()"
              >ส่งออกข้อมูล</b-dropdown-item-button
            >
          </b-dropdown>
        </b-col>
      </CRow>

      <div class="mt-3 bg-white p-3 py-sm-0">
        <b-row>
          <b-col md="12">
            <div class="mt-3 bg-white">
              <b-row class="no-gutters">
                <b-col>
                  <b-table
                    class="table-list"
                    striped
                    responsive
                    hover
                    :items="items"
                    :fields="fields"
                    :busy="isBusy"
                    show-empty
                    empty-text="ไม่พบข้อมูล"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                  >
                    <template v-slot:cell(productName)="data">
                      <router-link
                        :to="'/product/details/' + data.item.productId"
                        target="_blank"
                        class="text-dark"
                      >
                        {{ data.item.productName }}
                      </router-link>
                    </template>
                    <template v-slot:cell(productImageUrl)="data">
                      <div class="position-relative">
                        <div
                          class="square-box b-contain"
                          v-bind:style="{
                            'background-image':
                              'url(' +
                              data.item.productImageUrl +
                              ')',
                          }"
                        ></div>
                      </div>
                    </template>
                    <template v-slot:cell(sales)="data">
                      <span>{{ data.item.sales | numeral("0,0.00") }}</span>
                    </template>
                    <template v-slot:cell(totalUnit)="data">
                      <span>{{ data.item.totalUnit | numeral("0,0") }}</span>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-black my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row class="no-gutters px-3 px-sm-0">
                <b-col
                  class="form-inline justify-content-center justify-content-sm-between"
                >
                  <div class="d-sm-flex m-3">
                    <b-pagination
                      v-model="filter.pageNo"
                      :total-rows="rows"
                      :per-page="filter.perPage"
                      class="m-md-0"
                      @change="pagination"
                      align="center"
                    ></b-pagination>

                    <div class="ml-2">
                      <p class="total-record-paging text-nowrap text-center">
                        {{ totalRowMessage }}
                      </p>
                    </div>
                  </div>

                  <b-form-select
                    class="mr-sm-3 select-page"
                    v-model="filter.perPage"
                    @change="hanndleChangePerpage"
                    :options="pageOptions"
                  ></b-form-select>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-sidebar
      id="sidebar-1"
      title="ค้นหาแบบละเอียด"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      ref="filterSidebar"
    >
      <div class="px-3 py-2">
        <div class="text-right">
          <button
            type="button"
            class="btn btn-link px-0"
            @click="onClearFilter()"
          >
            ล้างค่า
          </button>
        </div>

        <label class="label-text">วันเริ่มต้น</label>
        <datetime
          placeholder="กรุณากรอกวันที่"
          class="date-picker mb-3"
          format="dd MMM yyyy"
          v-model="filter.startDate"
        ></datetime>

        <label class="label-text">วันสิ้นสุด</label>
        <datetime
          placeholder="กรุณากรอกวันที่"
          class="date-picker mb-3"
          format="dd MMM yyyy"
          v-model="filter.endDate"
        ></datetime>

        <div class="text-center">
          <p class="text-danger" v-if="errorDate">
            วันสิ้นสุดต้องมากกว่าวันเริ่มต้น
          </p>
        </div>

        <InputSelect
          class="mb-4"
          title="ลูกค้า"
          name="roleId"
          :options="optionsRoleId"
          v-model="filter.roleId"
          @onDataChange="handleChangeRoleId"
          valueField="id"
          textField="name"
        />

        <div class="text-center mt-4">
          <button
            type="button"
            class="btn btn-purple button"
            @click="getDataByStatus"
          >
            ค้นหา
          </button>
        </div>
      </div>
    </b-sidebar>
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import axios from "axios";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import InputSelect from "@/components/inputs/InputSelect";

export default {
  components: {
    ModalLoading,
    InputSelect,
  },
  data() {
    return {
      statusData: null,
      performanceData: null,
      errorDate: false,
      campaignData: [],
      filter: {
        pageNo: 1,
        perPage: 10,
        search: "",
        roleId: 0,
        startDate: null,
        endDate: null,
        sortById: 1
      },
      lineChartData: {
        label: [],
        data: [],
      },
      optionsRoleId: [
        { id: 0, name: "กรุณาเลือกลูกค้า" },
        { id: 4, name: "ลูกค้าทั่วไป" },
        { id: 1, name: "ร้านค้าทั่วไป" },
      ],
      totalRowMessage: "",
      items: [],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      sortBy: 'productName',
      sortDesc: false,
      fields: [
        {
          key: "productImageUrl",
          label: "",
          class: "w-50px px-0",
        },
        {
          key: "sku",
          label: "SKU",
          tdClass: "text-left",
          sortable: true
        },
        {
          key: "productName",
          label: "รายละเอียด",
          tdClass: "text-left",
          sortable: true
        },
        {
          key: "sales",
          label: "ยอดขาย",
          class: "w-100px",
          sortable: true
        },
        {
          key: "totalUnit",
          label: "จำนวนที่ขายได้",
          class: "w-100px",
          sortable: true
        },
      ],
    };
  },
  watch: {
    sortBy(newValue, oldValue) {
      this.handleSorting(newValue)
    },
    sortDesc(newValue, oldValue) {
      this.handleSorting(this.sortBy, newValue)
    }
  },

  created: async function () {
    await this.init();
    await this.getAll();
    this.$isLoading = true;
  },
  methods: {
    async handleSorting(sortBy, sortDesc = false){
      if(sortBy == 'productName') {
        if(sortDesc) this.filter.sortById = 2
        else  this.filter.sortById = 1
      } else if(sortBy == 'totalUnit') {
        if(sortDesc) this.filter.sortById = 6
        else  this.filter.sortById = 5
      } else if(sortBy == 'sales') {
        if(sortDesc) this.filter.sortById = 4
        else  this.filter.sortById = 3
      } else if(sortBy == 'sku') {
        if(sortDesc) this.filter.sortById = 8
        else  this.filter.sortById = 7
      }
      await this.getRankProduct();
    },
    init() {
      let now = new Date();
      this.filter.endDate = this.$moment(now).format("YYYY-MM-DD");
      this.filter.startDate = this.$moment(now)
        .add(-1, "months")
        .format("YYYY-MM-DD");
    },
    getAll: async function () {
      await this.getRankProduct();
    },
    async getRankProduct() {
      this.$isLoading = false;
      let list = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/dashboard/GetDashboardRankProduct`,
        null,
        this.$headers,
        this.filter
      );

      if (list.result === 1) {
        this.items = list.detail.data;
        this.rows = list.detail.count;
      }

      this.$isLoading = true;
    },
    async getDataByStatus() {
      if (
        this.filter.startDate > this.filter.endDate &&
        this.filter.endDate != ""
      ) {
        this.errorDate = true;
        return;
      }

      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      await this.getAll();
    },
    async onClearFilter() {
      await this.init();
      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      await this.getAll();
    },
    handleChangeRoleId: async function (value) {
      this.filter.roleId = value;
    },
    exportFile: async function () {
      this.$refs.modalLoading.show();

      let path = "dashboard/ExportDashboardRankProduct";
      let filter = this.filter;

      await axios({
        url: `${this.$baseUrl}/api/${path}`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: filter,
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = this.$moment().format("DDMMYYYYhhmmss");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Dashboard-Rank-Product-${dateExcel}.xlsx`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
        });
      this.$refs.modalLoading.hide();
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.pageNo = 1;
        this.getAll();
      }, 800);
    },
    btnSearch() {
      this.filter.pageNo = 1;
      this.getAll();
    },
    pagination(Page) {
      this.filter.pageNo = Page;
      this.getAll();
    },
    hanndleChangePerpage(value) {
      this.filter.pageNo = 1;
      this.filter.perPage = value;
      this.getAll();
    },
  },
};
</script>

<style scoped>
.dashboard-status-box {
  width: 25%;
  display: inline-block;
}

.dashboard-status {
  border-radius: 5px;
  border: 1px solid #1085ff;
}

.dashboard-status-value {
  color: #1085ff;
  font-size: 20px;
  font-weight: bold;
  width: 25%;
  text-align: right;
}

.performance-box {
  width: 25% !important;
}

.performance-box div,
.campaign-data-box {
  border: 1px solid #dbdbdb;
}

.image {
  width: 100%;
  padding-top: 20%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* ::v-deep .slick-dots {
    padding-bottom: 10px;
  } */
.menuactive {
  color: #ffb300 !important;
}
@media (max-width: 1200px) {
  .performance-box {
    width: 100% !important;
  }

  .dashboard-status-box {
    width: 50%;
  }
}

@media (max-width: 767px) {
}

@media (max-width: 600px) {
  .f-12-mobile {
    font-size: 12px;
  }
}
</style>
